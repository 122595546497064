import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done F1E9DA
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				<audio src="/soundeffect.mp3" autoPlay={true} />
				{/* {props.config.msgFrom}: {props.config.msg} */}
				<motion.div

					initial={{ opacity: 0, y: 50 }}
					exit={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0 }}
				>
					<div className='relative'>
						<img alt="error" src="./tradeofferfix.png" className="h-[500px] w-[500px]" />
						<div className='absolute right-[1px] left-[1px] top-16 flex flex-col justify-center'>
							<div className='flex flex-row justify-center space-x-10'>
								<div className='bg-gray-700 rounded-md font-bold text-lg p-2 text-white min-w-[200px] max-w-[300px] text-center'>{props.config.msgFrom} offers: {props.config.offer}</div>
								<div className='bg-gray-700 rounded-md font-bold text-lg p-2 text-white min-w-[200px] max-w-[300px] text-center'>They Receive: {props.config.receive} </div>
							</div>
						</div>
					</div>
				</motion.div>
			</div >
		</AnimatePresence >
	);
}

